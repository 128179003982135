const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
var sparkRecognition;
if (isFirefox) {
    // Firefox no suporta la SpeechRecognition API
    sparkRecognition = false;
} else {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    sparkRecognition = new SpeechRecognition();
}


